import BGSLI3 from '../../assets/images/backgrounds/cov-8.png'
import BGSLI4 from '../../assets/images/backgrounds/cov-9.png'
import BGCase11 from '../../assets/images/case/case-1-1.png'
import BGCase12 from '../../assets/images/case/case-1-2.png'
import BGCase13 from '../../assets/images/case/case-1-3.png'
import BGCase14 from '../../assets/images/case/case-1-4.png'
import BGBL21 from '../../assets/images/blog/blog-2-1.png'
import BGBL22 from '../../assets/images/blog/blog-2-2.png'
import BGBL23 from '../../assets/images/blog/blog-2-3.png'
import BGCta1 from '../../assets/images/backgrounds/cta-1-1-bg.jpg'
import AnimatedNumber from "animated-number-react"
import OwlCarousel from 'react-owl-carousel'
import { Link, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import BGVImg1 from "../../assets/images/backgrounds/video-bg-1-1.jpg";
import BGImgBe1 from "../../assets/images/backgrounds/benefit-bg-1-1.jpg";

import AVA from '../../assets/images/learn.png'
import ABOUT from '../../assets/images/resources/homeAbout.png'

import Australia from '../../assets/images/country/Australia.png'
import Canada from '../../assets/images/country/Canada.png'
import Netherland from '../../assets/images/country/Netherland.png'
import NewZealand from '../../assets/images/country/NewZealand.png'
import Switzerland from '../../assets/images/country/Switzerland.png'
import UK from '../../assets/images/country/UK.png'

import TESTI1 from '../../assets/images/team/1.png'
import TESTI2 from '../../assets/images/team/2.png'
import TESTI3 from '../../assets/images/team/3.png'
import TESTI4 from '../../assets/images/team/4.png'

import TESTIMG from '../../assets/images/resources/testi.png'
import ABOUTIMG from '../../assets/images/resources/AboutUs.png'
import SERVICEIMG1 from '../../assets/images/services/home/InhouseServices.png'
import SERVICEIMG2 from '../../assets/images/services/home/CommunityParticipation.png'
import SERVICEIMG3 from '../../assets/images/services/home/AgedCare.png'
import SERVICEIMG4 from '../../assets/images/services/home/AlliedHealthcare.png'

import ICON1 from '../../assets/images/icons/1.png'
import ICON2 from '../../assets/images/icons/2.png'
import ICON3 from '../../assets/images/icons/3.png'
import ICON4 from '../../assets/images/icons/4.png'
import ICON5 from '../../assets/images/icons/5.png'
import ICON6 from '../../assets/images/icons/6.png'



const Home3 = () => {
    const activeRef = useRef(null);
    const [ytShow, setytShow] = useState(false);
    const [sticky, setSticky] = useState(false);
    const location = useLocation()
    const path = location.pathname
    const [menu, setmenu] = useState({})
    const carouselRef = useRef();
    const [activeTab, setactiveTab] = useState("repair");

    const faqs = [
        {
            question: "What services does Glorious Support Services provide?",
            answer: "Glorious Support Services offers a wide range of services, including In Home Services, Community Access, Aged Care, and Allied Health Services. Our offerings encompass more than 16 specialized services tailored to meet the unique needs of individuals with disabilities."
        },
        {
            question: "How do I know which services are suitable for me or my loved ones?",
            answer: "Our team conducts a thorough assessment to understand the specific needs and preferences of each individual. We then work collaboratively with clients and their families to determine the most suitable services, ensuring a personalized and effective support plan."
        },
        {
            question: "Is Glorious Support Services available only in Southeast Melbourne?",
            answer: "While our primary location is in Southeast Melbourne, we provide services across the entire Melbourne region. Our goal is to make our comprehensive support accessible to a broad community."
        },
        {
            question: "What sets Glorious Support Services apart from other disability support providers?",
            answer: "Glorious Support Services distinguishes itself through six years of expertise, a client-centered approach, a diverse range of services, community engagement initiatives, a passionate team of professionals, and a commitment to empowering individuals through informed choices."
        },
        // {
        //     question: "How long does the home loan application process take?  ",
        //     answer: "The home loan application process can vary depending on factors such as lender requirements, documentation completeness, and market conditions. Generally, it can take anywhere from a few days to a few weeks. Our team aims to streamline the process and provide a timely response."
        // },


    ]
    const [clickedOne, setClickedOne] = useState(null);

    const [clicked, setClicked] = useState(null);
    const faqsone = [
        {
            question: "How can I join the Glorious Support Services team?",
            answer: "We welcome individuals passionate about making a positive impact to explore career opportunities with us. Visit our Careers page to view available job roles and learn more about joining our dedicated team."
        },
        {
            question: "What is the process for initiating support services with Glorious Support Services?",
            answer: "To initiate support services, you can contact us through our website or give us a call. Our team will guide you through the assessment process, discuss your needs, and create a personalized support plan that aligns with your goals."
        },
        {
            question: "Are your services covered by the National Disability Insurance Scheme (NDIS)?",
            answer: "Yes, Glorious Support Services is an NDIS registered provider. Our services are eligible for NDIS funding, and we assist clients in navigating the NDIS process to access the support they need."
        },
        {
            question: "How does Glorious Support Services contribute to community engagement? ",
            answer: "We actively engage in community initiatives, organizing social events, activities, and support networks. Our commitment to community engagement extends beyond individual care, creating a sense of belonging and connection for our clients."
        },
        // {
        //     question: "How can I calculate my borrowing capacity and estimate my mortgage repayments?  ",
        //     answer: "Our website features handy financial calculators that can help you estimate your borrowing capacity based on your income, expenses, and other factors. You can also use these calculators to estimate your potential mortgage repayments. Feel free to explore these tools or contact us for personalized assistance."
        // },

    ]

    const activeMenu = () => {
        if (path === "/" || path === "/home-02" || path === "/home-03") {
            setmenu({ home: true })
        } else if (path === "/about") {
            setmenu({ about: true })
        } else if (path === "/service" || path === "/servicedetails") {
            setmenu({ services: true })
        } else if (path === "/team" || path === "/teamdetails" || path === "/creditaudit" || path === "/creditrepair" || path === "/careers" || path === "/faqs" || path === "/applynow") {
            setmenu({ pages: true })
        } else if (path === "/personalloan" || path === "/loaneligibility" || path === "/homeloan" || path === "/homeloaneligibility" || path === "/compareEMI" || path === "/monthlyloan") {
            setmenu({ features: true })
        }
        else if (path === "/newsmain" || path === "/newsdetails") {
            setmenu({ news: true })
        }
        else if (path === "/contact") {
            setmenu({ contact: true })
        } else {
            setmenu({ home: true })
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? setSticky(true) : setSticky(false);
    }

    useEffect(() => {
        window.scroll(0, 0)
        activeMenu()
    }, [path]);


    const sliderOptionsTwo = {
        loop: true,
        items: 1,
        navText: ["", ""],
        margin: 0,
        dots: false,
        nav: true,
        // animateOut: "slideInRight",
        // animateIn: "slideInRight",
        smartSpeed: 1000,
        autoplay: true,
        autoplayTimeout: 7000,
        autoplayHoverPause: false
    }

    const sliderFeature = {
        loop: true,
        items: 1,
        navText: ["<span class='slider-one__carousel__btn__left'></span>", "<span class='slider-one__carousel__btn__left'></span>"],
        margin: 30,
        dots: true,
        nav: true,
        // animateOut: "slideOutDown",
        // animateIn: "fadeIn",
        smartSpeed: 1000,
        autoplay: true,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            576: {
                margin: 30,
                items: 1
            },
            768: {
                margin: 30,
                items: 2
            },
            992: {
                margin: 30,
                items: 3
            },
            1200: {
                margin: 30,
                items: 4
            }
        },
    }
    const sliderTestimonial = {
        loop: true,
        items: 1,
        navText: ["<span class='slider-one__carousel__btn__left'></span>", "<span class='slider-one__carousel__btn__left'></span>"],
        margin: 0,
        dots: true,
        nav: true,
        // animateOut: "slideOutDown",
        // animateIn: "fadeIn",
        smartSpeed: 1000,
        autoplay: true,
        autoplayTimeout: 7000,
        autoplayHoverPause: false,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            576: {
                margin: 30,
                items: 1
            },
            768: {
                margin: 30,
                items: 2
            },
            992: {
                margin: 30,
                items: 3
            },
            1200: {
                margin: 30,
                items: 3
            }
        },
    }

    const sliderCard = {
        loop: true,
        items: 1,
        navText: ["", ""],
        margin: 30,
        dots: true,
        nav: false,
        // animateOut: "slideOutDown",
        // animateIn: "fadeIn",
        smartSpeed: 1000,
        autoplay: true,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            576: {
                margin: 30,
                items: 1
            },
            768: {
                margin: 30,
                items: 2
            },
            992: {
                margin: 30,
                items: 3
            },
            1200: {
                margin: 30,
                items: 4
            }
        },

    }

    return (
        <>
            {/* <nav class={`main-menu main-menu--three ${sticky && "stricky-header stricked-menu stricky-fixed"}`}>
            <div class="container-fluid">
                <div class="main-menu__logo">
                    <Link to="/">
                        <img src="assets/images/logo-light-f.png" width="140" height="51" alt="Finlon" />
                    </Link>
                </div>
                <div class="main-menu__nav">
                    <ul class="main-menu__list">
                        <li class={`dropdown ${menu.home && "current"}`}>
                            <Link to="/">Home</Link>
                            <ul>
                                <li> <Link to="/">Home One</Link> </li>
                                <li><Link to="home-02">Home Two</Link></li>
                                <li><Link to="home-03">Home Three</Link></li>
                                <li class="dropdown">
                                    <Link to="#">Header Styles</Link>
                                    <ul>
                                        <li><Link to="/">Header One</Link></li>
                                        <li><Link to="home-02">Header Two</Link></li>
                                        <li><Link to="home-03">Header Three</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class= {`about ${menu.about && "current"}`}>
                            <Link to="/about">About</Link>
                        </li>
                        <li class={`dropdown ${menu.services && "current"}`}><Link to="/services">Services</Link>
                            <ul>
                                <li><Link to="/service">Services 01</Link></li>
                                <li><Link to="/servicesdetails">Service Details</Link></li>
                            </ul>
                        </li>
                        <li class={`dropdown ${menu.pages && "current"}`}><Link to="#">Pages</Link>
                            <ul>
                                <li><Link to="/team">Team</Link></li>
                                <li><Link to="/teamdetails">Team Details</Link></li>
                                <li><Link to="/creditrepair">Credit Repair</Link></li>
                                <li><Link to="/creditaudit">Credit Audit</Link></li>
                                <li><Link to="/careers">Careers</Link></li>
                                <li><Link to="/faqs">Faqs</Link></li>
                                <li><Link to="/applynow">Schedule An Appointment</Link></li>
                            </ul>
                        </li>
                        <li class={`dropdown ${menu.features && "current"}`}><Link to="#">Features</Link>
                            <ul>
                                <li><Link to="/personalloan">Personal Loan</Link></li>
                                <li><Link to="/loaneligibility">Loan Eligibility</Link></li>
                                <li><Link to="/homeloan">Home Loan</Link></li>
                                <li><Link to="/homeloaneligibility">Home Loan Eligibility</Link></li>
                                <li><Link to="/compareEMI">Compare EMI</Link></li>
                                <li><Link to="/monthlyloan">Monthly Calculator</Link></li>
                            </ul>
                        </li>
                        <li class={`dropdown ${menu.news && "current"}`}><Link to="#">News</Link>
                            <ul>
                                <li><Link to="/newsmain">News</Link></li>
                                <li><Link to="/newsdetails.">News Details</Link></li>
                            </ul>
                        </li>
                        <li class= {`contact ${menu.about && "current"}`}><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
                <div class="main-menu__right">
                    <Link to="#" class="main-menu__toggler mobile-nav__toggler">
                        <i class="fa fa-bars"></i>
                    </Link>
                    <Link to="#" class="main-menu__search search-toggler">
                        <i class="icon-magnifying-glass"></i>
                    </Link>
                    <Link to="/applynow" class="thm-btn main-menu__btn">Schedule An Appointment</Link>
                    <Link to="tel:926668880000" class="main-menu__contact">
                        <span class="main-menu__contact__icon">
                            <i class="icon-phone"></i>
                        </span>
                        <span class="main-menu__contact__text">
                            <strong>92 666 888 0000</strong>
                            Mon to Fri: 9 am to 6 pm
                        </span>
                    </Link>
                </div>
            </div>
        </nav> */}
            <div className="stricky-header stricked-menu main-menu main-menu--three">
                <div className="sticky-header__content"></div>
            </div>
            <section className="slider-one slider-one--three">
                <OwlCarousel ref={carouselRef} className="thm-owl__carousel thm-owl__carousel--custom-nav owl-carousel owl-theme owl-dot-style-one" data-owl-nav-prev=".slider-one__carousel__btn__left" data-owl-nav-next=".slider-one__carousel__btn__right" {...sliderOptionsTwo} >
                    <div className="item">
                        <div className="slider-one__item">
                            <div className="slider-one__image" style={{ backgroundImage: `url(${BGSLI3})` }}>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 text-left">
                                        <h2 className="slider-one__title">Experience personalized care and support services</h2>
                                        <p className="slider-one__text">A brighter future for individuals and fostering stronger, connected communities.</p>
                                        <div className="slider-one__btns">
                                            <Link to="/contact" className="thm-btn" style={{width:"200px"}} >Contact Us</Link>
                                            {/* <a className="thm-btn3" >0413 599 838</a> */}
                                            {/* <a href='https://calendly.com/learnxcel' target='_blank' className="thm-btn" >Schedule An Appoinment</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="slider-one__item">
                            <div className="slider-one__image " style={{ backgroundImage: `url(${BGSLI4})` }}>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 text-left">
                                        <h2 className="slider-one__title">Your Path to Wellness Begins Here</h2>
                                        <p className="slider-one__text">Explore our comprehensive services under four main pillars</p>
                                        <div className="slider-one__btns">
                                            <Link to="/contact" className="thm-btn" style={{width:"200px"}} >Contact Us</Link>
                                            {/* <a className="thm-btn3" >0413 599 838</a> */}
                                            {/* <a href='https://calendly.com/learnxcel' target='_blank' className="thm-btn" >Contact Us</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
                {/* <div className="slider-one__carousel__btn">
                    <button className="slider-one__carousel__btn__left" onClick={() => carouselRef.current.next()}>
                        <i className="fa fa-angle-left"></i>
                    </button>
                    <button className="slider-one__carousel__btn__right" onClick={() => carouselRef.current.prev()}>
                        <i className="fa fa-angle-right"></i>
                    </button>
                </div> */}
            </section>

            <section className="about-four pt-20 pb-60">
                {/* <div className="about-four__shape"></div> */}
                <div className="container">
                    <div className="row row-gutter-y-50">
                        <div className="col-lg-6">
                            <div className="about-four__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    <h2 className="block-title__title">
                                        Welcome to Glorious Support Services
                                    </h2>
                                </div>
                                {/* <div className="about-four__box">
                                    <div className="about-four__box__icon">
                                        <i className="icon-loan"></i>
                                    </div>
                                    <div className="about-four__box__content">
                                        <h3 className="about-four__box__title">
                                            We’re in this business since 1987 and we provide the best
                                            services.
                                        </h3>
                                    </div>
                                </div> */}
                                <p className="about-four__text">
                                    At Glorious Support Services, we're dedicated to providing unparalleled care and support to individuals and communities. Our commitment to excellence shines through our comprehensive range of services designed to enhance lives and foster well-being. Explore how we can make a positive difference for you or your loved ones.
                                </p>

                                {/* <div className="row row-gutter-y-20">
                                    <div className="col-md-6">
                                        <div className="about-four__feature">
                                            <div className="about-four__feature__content">
                                                <div className="about-four__feature__icon">
                                                    <i className="icon-confirmation"></i>
                                                </div>
                                                <h3 className="about-four__feature__title">
                                                    Direct card payment
                                                </h3>
                                            </div>
                                            <div className="about-four__feature__text">
                                                Lorem ipsum dolor sit ame ed consectetur nod.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about-four__feature">
                                            <div className="about-four__feature__content">
                                                <div className="about-four__feature__icon">
                                                    <i className="icon-confirmation"></i>
                                                </div>
                                                <h3 className="about-four__feature__title">
                                                    Direct card payment
                                                </h3>
                                            </div>
                                            <div className="about-four__feature__text">
                                                Lorem ipsum dolor sit ame ed consectetur nod.
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="about-four__btns">
                                    <Link to="/about" className="thm-btn thm-btn--dark-hover" style={{width:"200px"}} >
                                        Discover More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-four__image">
                                {/* <div className="about-four__image__bg"></div>
                                <div className="about-four__image__shape"></div> */}
                                <img src={ABOUTIMG} alt="" />
                                <div className="about-four__image__caption">
                                    <h3 className="about-four__image__caption__year">
                                        5<i>+</i>
                                    </h3>
                                    <p className="about-four__image__caption__text">
                                        Years Experience
                                    </p>
                                </div>
                                {/* <div className="about-four__image__floated">Finance</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="about-six pt-20 pb-20">
                <div className="container">
                    <div className="row row-gutter-y-60">
                        <div className="col-lg-6 order-2 order-sm-1">
                            <div className="about-six__image">

                                <img src={ABOUT} alt="" width={500} />

                                <div className="about-six__image__caption wow fadeInRight" data-wow-duration="1500ms">
                                    <h4 className="about-six__image__caption__year count-box">
                                        <span className="count-text" data-stop="20" data-speed="1500">5+</span>
                                    </h4>
                                    <p className="about-six__image__caption__text">Years of
                                        practicing</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-sm-2">
                            <div className="about-six__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    <h2 className="block-title__title">Your Expert Navigator in Student Migrations</h2>
                                </div>
                                <ul className="list-unstyled about-six__list">
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Dynamic & Diversified
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Solution-Oriented
                                    </li>
                                </ul>
                               

                                <p className="about-six__text">We are a team of individuals who understand the transformative power of education. Our mission is
                                    to be the bridge between your academic aspirations and the world's top educational institutions.</p>
                                <div className="about-six__author">
                                    <div className="about-six__author__image">
                                        <img src={AVA} alt="" width={76} />
                                    </div>
                                    <div className="about-six__author__name">
                                        Learnxcel
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section> */}


            <section className="case-one pb-60 ">
                <div className="container-fluid">
                    <div className="block-title text-center">
                        <p className="block-title__tagline">WHAT WE OFFER</p>
                        <h2 className="block-title__title">Our Service Pillars</h2>
                    </div>
                    <div className="case-one__carousel container ">
                        {/* <OwlCarousel className="thm-owl__carousel owl-carousel owl-theme owl-dot-style-one" {...sliderFeature} data-owl-options='{
                            "loop": true,
                            "items": 1,
                            "autoplay": true,
                            "autoplayHoverPause": true,
                            "autoplayTimeout": 5000,
                            "smartSpeed": 500,
                            "nav": true,
                            "dots": true,
                            "margin": 30,
                            "responsive": {
                              "0": {
                                "items": 1
                              },
                              "768": {
                                "items": 2
                              },
                              "992": {
                                "items": 3
                              },
                              "1200": {
                                "items": 4
                              }
                            }
                          }'>
                           

                        </OwlCarousel> */}

                        <div className='row row-gutter-y-20'>

                            <div className="item col-12 col-sm-6">
                                <div className="case-card-one d-flex justify-content-center">
                                    {/* <Link to="/servicedetails" className="case-card-one__link">
                                        <i className="fa fa-plus"></i>
                                    </Link> */}
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${SERVICEIMG1})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="/in-home-services">In Home Services</Link></h3>
                                        {/* <p className="case-card-one__text">Finance</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="item col-12 col-sm-6">
                                <div className="case-card-one d-flex justify-content-center">
                                    {/* <Link to="/servicedetails" className="case-card-one__link">
                                        <i className="fa fa-plus"></i>
                                    </Link> */}
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${SERVICEIMG2})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="/community-participation">Community Participation</Link></h3>
                                        {/* <p className="case-card-one__text">Finance</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="item col-12 col-sm-6">
                                <div className="case-card-one d-flex justify-content-center">
                                    {/* <Link to="/servicedetails" className="case-card-one__link">
                                        <i className="fa fa-plus"></i>
                                    </Link> */}
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${SERVICEIMG3})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="/aged-care">Aged Care</Link></h3>
                                        {/* <p className="case-card-one__text">Finance</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="item col-12 col-sm-6">
                                <div className="case-card-one d-flex justify-content-center">
                                    {/* <Link to="/servicedetails" className="case-card-one__link">
                                        <i className="fa fa-plus"></i>
                                    </Link> */}
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${SERVICEIMG4})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="/allied-health-services">Allied Health
                                            Services</Link></h3>
                                        {/* <p className="case-card-one__text">Finance</p> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="benefit-one pt-20">
                {/* <div
                    className="benefit-one__shape-1 wow fadeInLeft"
                    data-wow-delay="000ms"
                    data-wow-duration="1500ms"
                    style={{ backgroundImage: `url(${BGImgBe1})` }}
                ></div> */}
                {/* <div className="benefit-one__shape-2"></div> */}
                <div className="container">
                    <div className="row row-gutter-y-60">
                        {/* <div className="col-lg-6">
                            <div
                                className="benefit-one__image wow fadeInUp"
                                data-wow-duration="1500ms"
                                data-wow-delay="500ms"
                            >
                                <img src="assets/images/resources/benefit-1-1.png" alt="" />
                                <div className="benefit-one__image__caption">
                                    <h3 className="benefit-one__image__title">99.9%</h3>
                                    <p className="benefit-one__image__text">
                                        Success Rates Guarantee
                                    </p>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-12">
                            <div className="benefit-one__content">
                                <div className="block-title text-center">
                                    <p className="block-title__tagline">WHY WE ARE THE BEST</p>
                                    <h2 className="block-title__title">
                                        Why you should choose our company
                                    </h2>
                                </div>
                                {/* <p className="benefit-one__text">
                                    Nullam vel nibh facilisis lectus fermentum ultrices quis non
                                    risus. Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit. In hac habitasse platea dictumst. Duis porta, quam ut
                                    finibus ultrices, lorem lacus congue lorem et rutrum sapien
                                    magna tincidunt magna.
                                </p> */}
                                {/* <div className="benefit-one__box">
                                    <div className="benefit-one__box__icon">
                                        <i className="icon-bank"></i>
                                    </div>
                                    <div className="benefit-one__box__content">
                                        <h3 className="benefit-one__box__title">
                                            Lowest bank fees
                                        </h3>
                                        <p className="benefit-one__box__text">
                                            Lorem ipsum dolor consectetur notte massa sapien samet.
                                            Aucibus sed sem non, mattis commodo nisi.
                                        </p>
                                    </div>
                                </div>
                                <div className="benefit-one__box">
                                    <div className="benefit-one__box__icon">
                                        <i className="icon-payment"></i>
                                    </div>
                                    <div className="benefit-one__box__content">
                                        <h3 className="benefit-one__box__title">
                                            Up to 20.000$ limit
                                        </h3>

                                        <p className="benefit-one__box__text">
                                            Lorem ipsum dolor consectetur notte massa sapien samet.
                                            Aucibus sed sem non, mattis commodo nisi.
                                        </p>
                                    </div>
                                </div>
                                <div className="benefit-one__box">
                                    <div className="benefit-one__box__icon">
                                        <i className="icon-smartphone-1"></i>
                                    </div>
                                    <div className="benefit-one__box__content">
                                        <h3 className="benefit-one__box__title">Easy in 3 steps</h3>
                                        <p className="benefit-one__box__text">
                                            Lorem ipsum dolor consectetur notte massa sapien samet.
                                            Aucibus sed sem non, mattis commodo nisi.
                                        </p>
                                    </div>
                                </div> */}
                                <div className="row row-gutter-y-20 d-flex justify-content-center">

                                    <div className="row row-gutter-y-10 d-flex justify-content-center">


                                        <div className="col-12 col-lg-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">

                                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Student Essentials/' target='_blank' > */}
                                            <div className="career-one__item">
                                                {/* <div className="career-one__icon">
                                                    <i className="icon-flowchart"></i>
                                                </div> */}
                                                <img src={ICON1} className="mb-30" />
                                                <div className="career-one__content">
                                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Student Essentials/' target='_blank' > */}
                                                    <h3 className="career-one__title">Tailored and Comprehensive Services</h3>
                                                    {/* </a> */}

                                                    <p className="career-one__text" style={{ color: "#68686F" }}>We stand out by offering many services under one roof for individual needs. Our comprehensive approach ensures that everyone receives personalized care, fostering a holistic and effective support system.</p>

                                                    {/* <p className="career-one__text" style={{ color: "#68686F" }}>With our in-depth knowledge of various academic disciplines and institutions worldwide, we assist you in selecting the best courses and universities that align with your interests and career aspirations. Your dreams are our priority, and we're committed to guiding you toward the educational opportunities that will shape your future</p> */}
                                                </div>
                                                {/* <div className="career-one__btns">
            <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
        </div> */}
                                            </div>
                                            {/* </a> */}
                                        </div>

                                        <div className="col-12 col-lg-6  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">

                                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Saving_Student Essentials/' target='_blank' > */}

                                            <div className="career-one__item">
                                                {/* <div className="career-one__icon">
                                                    <i className="icon-bill"></i>
                                                </div> */}
                                                <img src={ICON2} className="mb-30" />
                                                <div className="career-one__content">

                                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Saving_Student Essentials/' target='_blank' > */}
                                                    <h3 className="career-one__title">6 Years of Expertise</h3>
                                                    {/* </a> */}

                                                    <p className="career-one__text" style={{ color: "#68686F" }}>Benefit from our wealth of experience garnered over six years of dedicated service. Our seasoned team has successfully worked with various conditions, including Down syndrome, intellectual disabilities, autism, Angelman's syndrome, behaviours of concern, physical disabilities, and more.</p>

                                                    {/* <p className="career-one__text" style={{ color: "#68686F" }}>Our experienced team will also work with you to craft the perfect Statement of Purpose (SOP) that highlights your strengths and aspirations, making your application stand out. We provide invaluable guidance at every step, ensuring that you submit a compelling application that increases your chances of admission to your preferred institutions.</p> */}
                                                </div>
                                                {/* <div className="career-one__btns">
            <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
        </div> */}
                                            </div>
                                            {/* </a> */}
                                        </div>

                                        <div className="col-12 col-lg-6  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">

                                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Stamp_Duty_Student Essentials/' target='_blank' > */}

                                            <div className="career-one__item">
                                                {/* <div className="career-one__icon">
                                                    <i className="icon-portfolio"></i>
                                                </div> */}
                                                <img src={ICON3} className="mb-30" />
                                                <div className="career-one__content">

                                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Stamp_Duty_Student Essentials/' target='_blank' > */}
                                                    <h3 className="career-one__title">Client-Centered Approach</h3>

                                                    {/* </a> */}

                                                    <p className="career-one__text" style={{ color: "#68686F" }}>At Glorious Support Services, our commitment revolves around you. We prioritize your goals, preferences, and unique requirements, creating a client-centered approach that places your well-being and aspirations at the forefront of our services.</p>

                                                    {/* <p className="career-one__text" style={{ color: "#68686F" }}>We guide you through every step, from gathering the required documents to preparing for interviews (if necessary). Our goal is to maximize your chances of visa approval, making your dream of studying abroad a reality.</p> */}
                                                </div>
                                                {/* <div className="career-one__btns">
            <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
        </div> */}
                                            </div>
                                            {/* </a> */}
                                        </div>
                                        <div className="col-12 col-lg-6  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Student Essentials/' target='_blank' > */}

                                            <div className="career-one__item">
                                                {/* <div className="career-one__icon">
                                                    <i className="icon-briefcase"></i>
                                                </div> */}
                                                <img src={ICON4} className="mb-30" />
                                                <div className="career-one__content">
                                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Student Essentials/' target='_blank' > */}
                                                    <h3 className="career-one__title">Empowering Through Choice
                                                    </h3>

                                                    {/* </a> */}

                                                    <p className="career-one__text" style={{ color: "#68686F" }}>We believe in the power of choice and options. Our dedicated team works collaboratively with you to ensure that you have the information and support needed to make informed decisions about your life, empowering you to lead a life of your choosing.</p>

                                                    {/* <p className="career-one__text" style={{ color: "#68686F" }}>We aim to provide you with the knowledge and resources you need to embark on your academic adventure with peace of mind.</p> */}

                                                    {/* <p className="career-one__text" style={{ color: "#68686F" }}>At LearnXcel, we believe that a well-rounded support system is essential for a successful
                academic journey. These services, including student health cover, accommodation,
                banking, and money transfers, are integral to your overall well-being and experience while
                studying abroad. Let us help you take care of these practical aspects so you can fully
                embrace your academic adventure.
            </p> */}
                                                </div>
                                                {/* <div className="career-one__btns">
            <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
        </div> */}
                                            </div>
                                            {/* </a> */}
                                        </div>
                                        <div className="col-12 col-lg-6  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Student Essentials/' target='_blank' > */}

                                            <div className="career-one__item">
                                                {/* <div className="career-one__icon">
                                                    <i className="icon-house"></i>
                                                </div> */}
                                                <img src={ICON5} className="mb-30" />
                                                <div className="career-one__content">
                                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Student Essentials/' target='_blank' > */}
                                                    <h3 className="career-one__title">Community Engagement Initiatives
                                                    </h3>

                                                    {/* </a> */}

                                                    <p className="career-one__text" style={{ color: "#68686F" }}>Beyond individual care, we actively engage in community initiatives. Through social events, activities, and support networks, we foster a sense of belonging and connection, promoting a supportive community environment for our clients.</p>

                                                    {/* <p className="career-one__text" style={{ color: "#68686F" }}>Our team is here to address any concerns you may have and to make your entry into your host country as stress-free as possible</p> */}

                                                    {/* <p className="career-one__text" style={{ color: "#68686F" }}>At LearnXcel, we believe that a well-rounded support system is essential for a successful
                academic journey. These services, including student health cover, accommodation,
                banking, and money transfers, are integral to your overall well-being and experience while
                studying abroad. Let us help you take care of these practical aspects so you can fully
                embrace your academic adventure.
            </p> */}
                                                </div>
                                                {/* <div className="career-one__btns">
            <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
        </div> */}
                                            </div>
                                            {/* </a> */}
                                        </div>
                                        <div className="col-12 col-lg-6  wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                            {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Student Essentials/' target='_blank' > */}

                                            <div className="career-one__item">
                                                {/* <div className="career-one__icon">
                                                    <i className="icon-house"></i>
                                                </div> */}
                                                <img src={ICON6} className="mb-30" />
                                                <div className="career-one__content">
                                                    {/* <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Student Essentials/' target='_blank' > */}
                                                    <h3 className="career-one__title">Passionate Team of Professionals
                                                    </h3>

                                                    {/* </a> */}

                                                    <p className="career-one__text" style={{ color: "#68686F" }}>Our success is driven by our passionate and skilled team of professionals. From experienced speech therapists to dedicated physiotherapists, our staff is committed to enhancing the lives of individuals with disabilities, bringing expertise, compassion, and enthusiasm to every interaction.</p>

                                                    {/* <p className="career-one__text" style={{ color: "#68686F" }}>Our team is here to address any concerns you may have and to make your entry into your host country as stress-free as possible</p> */}

                                                    {/* <p className="career-one__text" style={{ color: "#68686F" }}>At LearnXcel, we believe that a well-rounded support system is essential for a successful
                academic journey. These services, including student health cover, accommodation,
                banking, and money transfers, are integral to your overall well-being and experience while
                studying abroad. Let us help you take care of these practical aspects so you can fully
                embrace your academic adventure.
            </p> */}
                                                </div>
                                                {/* <div className="career-one__btns">
            <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
        </div> */}
                                            </div>
                                            {/* </a> */}
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        {/* <ul className="list-unstyled ml-0 about-two__list "
                                            style={{
                                                marginLeft: "-30px"
                                            }}
                                        >
                                            <li className='mb-20 text-center'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b style={{ fontSize: "25px" }}>Tailored and Comprehensive Services</b> <br /><span style={{ fontWeight: 400 }}>
                                                    We stand out by offering many services under one roof for individual needs. Our comprehensive approach ensures that everyone receives personalized care, fostering a holistic and effective support system.

                                                </span>
                                            </li>
                                            <li className='mb-20 text-center'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b style={{ fontSize: "25px" }}> 6 Years of Expertise</b>  <br /> <span style={{ fontWeight: 400 }}>
                                                    Benefit from our wealth of experience garnered over six years of dedicated service. Our seasoned team has successfully worked with various conditions, including Down syndrome, intellectual disabilities, autism, Angelman's syndrome, behaviours of concern, physical disabilities, and more.
                                                </span>
                                            </li>
                                            <li className='mb-20 text-center'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b style={{ fontSize: "25px" }}>Client-Centered Approach</b> <br /><span style={{ fontWeight: 400 }}>At Glorious Support Services, our commitment revolves around you. We prioritize your goals, preferences, and unique requirements, creating a client-centered approach that places your well-being and aspirations at the forefront of our services.

                                                </span>
                                            </li>
                                            <li className='mb-20 text-center'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b style={{ fontSize: "25px" }}>Empowering Through Choice</b>  <br /><span style={{ fontWeight: 400 }}>We believe in the power of choice and options. Our dedicated team works collaboratively with you to ensure that you have the information and support needed to make informed decisions about your life, empowering you to lead a life of your choosing.

                                                </span>
                                            </li>

                                            <li className='mb-20 text-center'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b style={{ fontSize: "25px" }}>Community Engagement Initiatives</b>  <br /><span style={{ fontWeight: 400 }}>Beyond individual care, we actively engage in community initiatives. Through social events, activities, and support networks, we foster a sense of belonging and connection, promoting a supportive community environment for our clients.

                                                </span>
                                            </li>

                                            <li className='mb-20 text-center'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b style={{ fontSize: "25px" }}>Passionate Team of Professionals</b>  <br /><span style={{ fontWeight: 400 }}>Our success is driven by our passionate and skilled team of professionals. From experienced speech therapists to dedicated physiotherapists, our staff is committed to enhancing the lives of individuals with disabilities, bringing expertise, compassion, and enthusiasm to every interaction.

                                                </span>
                                            </li>
                                        </ul> */}

                                        {/* <div className="col-md-6">
        <div className="about-five__video">
            <img src="assets/images/resources/about-5-v-1.png" alt="" />
            <Link to="#" onClick={() => setytShow(true)} className="video-popup about-five__video__btn">
                <i className="fa fa-play"></i>
                <span className="ripple"></span>
            </Link>
        </div>
    </div> */}
                                    </div>
                                    {/* <div className="col-md-6">
    <div className="about-one__box">
        <div className="about-one__box__icon">
            <i className="icon-money-transfer"></i>
        </div>
        <div className="about-one__box__content">
            <h3 className="about-one__box__title">International banking
                & finance</h3>
            <p className="about-one__box__text">Nullam mollis elit quis dus is lacinia not efficitur
            </p>
        </div>
    </div>
</div>
<div className="col-md-6">
    <div className="about-one__box">
        <div className="about-one__box__icon">
            <i className="icon-bank-transfer"></i>
        </div>
        <div className="about-one__box__content">
            <h3 className="about-one__box__title">International banking
                & finance</h3>
            <p className="about-one__box__text">Nullam mollis elit quis dus is lacinia not efficitur
            </p>
        </div>
    </div>
</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* <section
                className="video-one video-one--home pt-240 pb-120"
                style={{ backgroundImage: `url(${BGVImg1})`, marginTop: "-40px" }}
            >
                <div className="video-one__shape"></div>
                <div className="container">
                    <div className="row row-gutter-y-50">
                        <div className="col-lg-6">
                            <div className="video-one__content">
                               
                                <h3 className="video-one__title">
                                    Discover Your Dream Education Abroad
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ul className="list-unstyled video-one__list">
                                <li>
                                    <i className="icon-tick"></i>
                                    Australia
                                </li>
                                <li>
                                    <i className="icon-tick"></i>
                                    UK
                                </li>
                                <li>
                                    <i className="icon-tick"></i>
                                    Canada
                                </li>
                                <li>
                                    <i className="icon-tick"></i>
                                    New Zealand
                                </li>
                                <li>
                                    <i className="icon-tick"></i>
                                    USA
                                </li>
                                <li>
                                    <i className="icon-tick"></i>
                                    Malaysia
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section> */}




            {/* <section className="call-to-action-four text-center wow fadeInUp" data-wow-duration="1500ms">
                <div className="container">
                    <div className="call-to-action-four__inner">
                        <div className="call-to-action-four__shape-1"></div>
                        <div className="call-to-action-four__shape-2"></div>
                        <p className="call-to-action-four__text">
                            Join us and step into a world of limitless possibilities. <br />Reach out to our team today and let's chart
                            out your path to the future!

                        </p>
                        <div className="form-one mt-1" style={{
                            paddingBottom: "20px"
                        }}>
                            <Link to="/contact" className="thm-btn">Contact Now</Link>
                        </div>
                    </div>
                </div>
            </section> */}




            {/* <section>
                <div className="client-carousel @@extraclassNameName pb-60">
                    <div className="container">
                        <div className="block-title text-center">
                         
                            <h2 className="block-title__title">Your Path to Acclaimed Higher Education</h2>
                        </div>
                        <Swiper
                            className="thm-swiper__slider swiper-container"
                            modules={[Autoplay]}
                            spaceBetween={30}
                            slidesPerView={5}
                            autoplay={{ delay: 5000 }}
                            breakpoints={{
                                "0": {
                                    "spaceBetween": 10,
                                    "slidesPerView": 2
                                },
                                "375": {
                                    "spaceBetween": 10,
                                    "slidesPerView": 2
                                },
                                "575": {
                                    "spaceBetween": 10,
                                    "slidesPerView": 3
                                },
                                "767": {
                                    "spaceBetween": 10,
                                    "slidesPerView": 4
                                },
                                "991": {
                                    "spaceBetween": 15,
                                    "slidesPerView": 5
                                },
                                "1199": {
                                    "spaceBetween": 30,
                                    "slidesPerView": 5
                                }
                            }}
                        >
                            <div className="swiper-wrapper">
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/brand-1-1.png" alt="" />
                                    </div>
                                </SwiperSlide>
                            </div>
                        </Swiper>
                    </div>
                </div>
            </section> */}

            {/* <section className="team-about pt-120 pb-120">
                <div className="container">
                    <div className="block-title text-center">
                        <p className="block-title__tagline">professional team</p>
                        <h2 className="block-title__title">Your Path to Acclaimed Higher Education</h2>
                    </div>
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src="assets/images/team/team-1-1.jpg" alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title"><Link to="/teamdetails">Connor Estrada</Link></h3>
                                        <p className="team-card__designation">ADVISOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src="assets/images/team/team-1-2.jpg" alt="Darrell Powell" />
                                    <div className="team-card__social">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title"><Link to="/teamdetails">Darrell Powell</Link></h3>
                                        <p className="team-card__designation">ADVISOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src="assets/images/team/team-1-3.jpg" alt="Carolyn Love" />
                                    <div className="team-card__social">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title"><Link to="/teamdetails">Carolyn Love</Link></h3>
                                        <p className="team-card__designation">ADVISOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}



            {/* <section className="case-one pb-120 ">
                <div className="container-fluid">
                    <div className="block-title text-center">
                        <p className="block-title__tagline">case study</p>
                        <h2 className="block-title__title">Discover our featured <br /> work studies</h2>
                    </div>
                    <div className="case-one__carousel">
                        <OwlCarousel className="thm-owl__carousel owl-carousel owl-theme owl-dot-style-one" {...sliderFeature} data-owl-options='{
                            "loop": true,
                            "items": 1,
                            "autoplay": true,
                            "autoplayHoverPause": true,
                            "autoplayTimeout": 5000,
                            "smartSpeed": 500,
                            "nav": true,
                            "dots": true,
                            "margin": 30,
                            "responsive": {
                              "0": {
                                "items": 1
                              },
                              "768": {
                                "items": 2
                              },
                              "992": {
                                "items": 3
                              },
                              "1200": {
                                "items": 4
                              }
                            }
                          }'>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase11})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Personal banking</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase12})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business planning</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase13})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Meger & acquistion</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase14})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business tie-ups</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase11})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Personal banking</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase12})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business planning</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase13})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Meger & acquistion</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase14})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business tie-ups</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase11})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Personal banking</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase12})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business planning</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase13})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Meger & acquistion</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="case-card-one">
                                    <Link to="/servicedetails" className="case-card-one__link"><i className="fa fa-plus"></i></Link>
                                    <div className="case-card-one__image" style={{ backgroundImage: `url(${BGCase14})` }}>
                                    </div>
                                    <div className="case-card-one__content">
                                        <h3 className="case-card-one__title"><Link to="#">Business tie-ups</Link></h3>
                                        <p className="case-card-one__text">Finance</p>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section> */}

            {/* <section className="testimonials-one pt-60 mb--40">
                <div className="container">
                    <div className="block-title text-center">
                        <p className="block-title__tagline">our testimonials</p>
                        <h2 className="block-title__title">
                            What they’re talking about <br /> our company
                        </h2>
                    </div>
                    <div className="row row-gutter-y-30"  >
                        <OwlCarousel className="thm-owl__carousel owl-carousel owl-theme owl-dot-style-one " {...sliderTestimonial} data-owl-options='{
                                        "loop": true,
                                        "items": 1,
                                        "autoplay": true,
                                        "autoplayHoverPause": true,
                                        "autoplayTimeout": 5000,
                                        "smartSpeed": 500,
                                        "nav": false,
                                        "dots": true,
                                        "margin": 30,
                                        "responsive": {
                                          "0": {
                                            "items": 1
                                          },
                                          "768": {
                                            "items": 1
                                          },
                                          "992": {
                                            "items": 3
                                          }
                                        }
                                      }'

                        >
                            <div className="item" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                <div className="testimonial-card">
                                    <div className="testimonial-card__info"> */}
                                        {/* <div className="testimonial-card__image">
                                            <img
                                                src="assets/images/resources/testi-1-1.png"
                                                alt="Delia Riley"
                                            />
                                        </div> */}
                                        {/* <div className="testimonial-card__meta">
                                            <div className="testimonial-card__stars">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                            <h3 className="testimonial-card__name">Zabian</h3>
                                            <p className="testimonial-card__designation">
                                            BoxHill
                                            </p>
                                        </div>
                                    </div>
                                    <span className="testimonial-card__line"></span>
                                    <div className="testimonial-card__text">
                                        <p>
                                            Glorious Support Services has been a game-changer for our family. The personalized care and attention to detail are unparalleled. Our lives are more enriched, and we are grateful for the support they provide.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                <div className="testimonial-card">
                                    <div className="testimonial-card__info"> */}
                                        {/* <div className="testimonial-card__image">
                                            <img
                                                src="assets/images/resources/testi-1-1.png"
                                                alt="Delia Riley"
                                            />
                                        </div> */}
                                        {/* <div className="testimonial-card__meta">
                                            <div className="testimonial-card__stars">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                            <h3 className="testimonial-card__name">Khya</h3>
                                            <p className="testimonial-card__designation">
                                            Oakleigh 
                                            </p>
                                        </div>
                                    </div>
                                    <span className="testimonial-card__line"></span>
                                    <div className="testimonial-card__text">
                                        <p>
                                            The dedication and compassion of the Glorious Support Services team are truly commendable. Their tailored approach has significantly improved our son's communication skills. We couldn't be happier with the progress he has made
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                <div className="testimonial-card">
                                    <div className="testimonial-card__info"> */}
                                        {/* <div className="testimonial-card__image">
                                            <img
                                                src="assets/images/resources/testi-1-1.png"
                                                alt="Delia Riley"
                                            />
                                        </div> */}
                                        {/* <div className="testimonial-card__meta">
                                            <div className="testimonial-card__stars">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                            <h3 className="testimonial-card__name">Andrew </h3>
                                            <p className="testimonial-card__designation">
                                            Narre Warren
                                            </p>
                                        </div>
                                    </div>
                                    <span className="testimonial-card__line"></span>
                                    <div className="testimonial-card__text">
                                        <p>
                                            As a caregiver, the respite care provided by Glorious Support Services has been a lifeline. It has allowed me the time to recharge while knowing that my loved one is in capable hands. Their commitment to both the individual and the caregiver is exceptional.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                <div className="testimonial-card">
                                    <div className="testimonial-card__info"> */}
                                        {/* <div className="testimonial-card__image">
                                            <img
                                                src="assets/images/resources/testi-1-1.png"
                                                alt="Delia Riley"
                                            />
                                        </div> */}
                                        {/* <div className="testimonial-card__meta">
                                            <div className="testimonial-card__stars">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                            <h3 className="testimonial-card__name">Mark</h3>
                                            <p className="testimonial-card__designation">
                                                Clayton South
                                            </p>
                                        </div>
                                    </div>
                                    <span className="testimonial-card__line"></span>
                                    <div className="testimonial-card__text">
                                        <p>
                                            Choosing Glorious Support Services was the best decision for our aging parents. The home care and assistance with daily activities have allowed them to age gracefully in the comfort of their home. The caregivers are not just professionals; they are part of our extended family
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                <div className="testimonial-card">
                                    <div className="testimonial-card__info"> */}
                                        {/* <div className="testimonial-card__image">
                                            <img
                                                src="assets/images/resources/testi-1-1.png"
                                                alt="Delia Riley"
                                            />
                                        </div> */}
                                        {/* <div className="testimonial-card__meta">
                                            <div className="testimonial-card__stars">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                            <h3 className="testimonial-card__name">Andrew</h3>
                                            <p className="testimonial-card__designation">
                                                Mulgrave
                                            </p>
                                        </div>
                                    </div>
                                    <span className="testimonial-card__line"></span>
                                    <div className="testimonial-card__text">
                                        <p>
                                            Our daughter has autism, and the community access services from Glorious Support Services have been a blessing. The social connections and engaging activities have helped her build meaningful relationships and enjoy a sense of belonging within the community.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </OwlCarousel>

                    </div>
                </div>
            </section> */}







            {/* <section className="team-about pt-120 pb-120">
                <div className="container">
                    <div className="block-title text-center">
                        <p className="block-title__tagline">professional team</p>
                        <h2 className="block-title__title">Meet the highly qualified <br /> team members</h2>
                    </div>
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src="assets/images/team/team-1-1.jpg" alt="Connor Estrada" />
                                    <div className="team-card__social">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title"><Link to="/teamdetails">Connor Estrada</Link></h3>
                                        <p className="team-card__designation">ADVISOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src="assets/images/team/team-1-2.jpg" alt="Darrell Powell" />
                                    <div className="team-card__social">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title"><Link to="/teamdetails">Darrell Powell</Link></h3>
                                        <p className="team-card__designation">ADVISOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                            <div className="team-card">
                                <div className="team-card__image">
                                    <img src="assets/images/team/team-1-3.jpg" alt="Carolyn Love" />
                                    <div className="team-card__social">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="team-card__content">
                                    <div className="team-card__content__inner">
                                        <h3 className="team-card__title"><Link to="/teamdetails">Carolyn Love</Link></h3>
                                        <p className="team-card__designation">ADVISOR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section className="faq-grid pt-120 pb-120">
                <div className="container">
                    <div className="block-title text-center">
                        <p className="block-title__tagline">FAQ</p>
                        <h2 className="block-title__title">Frequently Asked Questions</h2>
                    </div>
                    <div className="row row-gutter-y-20">
                        <div className="col-lg-6">
                            <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
                                {faqs.map((item, index) => (
                                    <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                        <div className="accrodion-title" onClick={() => setClicked(index)}>
                                            <h4>{item.question}</h4>
                                            <span className="accrodion-icon"></span>
                                        </div>
                                        {index === clicked && <div className="accrodion-content pl-20">
                                            <div className="inner">
                                                <p>{item.answer}</p>
                                            </div>
                                        </div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
                                {faqsone.map((item, index) => (
                                    <div className={`accrodion  wow fadeInUp ${index === clickedOne && "active"}`} key={index} data-wow-delay="0ms">
                                        <div className="accrodion-title" onClick={() => setClickedOne(index)}>
                                            <h4>{item.question}</h4>
                                            <span className="accrodion-icon"></span>
                                        </div>
                                        {index === clickedOne && <div className="accrodion-content pl-20">
                                            <div className="inner">
                                                <p>{item.answer}</p>
                                            </div>
                                        </div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="blog-three pb-120">
                <div className="container">
                    <div className="blog-three__top">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">Blogs</p>
                                    <h2 className="block-title__title">Meet the highly qualified <br /> team members</h2>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="blog-three__btns">
                                    <Link to="newsdetails" className="thm-btn">View All News</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4 col-md-12">
                            <div className="blog-card-two">
                                <div className="blog-card-two__image" style={{ backgroundImage: `url(${BGBL21})` }}>
                                </div>
                                <div className="blog-card-two__content">
                                    <div className="blog-card__meta">
                                        <Link to="newsdetails"><i className="far fa-user-circle"></i> by Admin</Link>
                                        <Link to="newsdetails"><i className="far fa-comments"></i> 2 Comments</Link>
                                    </div>
                                    <h3 className="blog-card-two__title"><Link to="newsdetails">Better changing to
                                        grow business faster </Link></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="blog-card-two">
                                <div className="blog-card-two__image" style={{ backgroundImage: `url(${BGBL22})` }}>
                                </div>
                                <div className="blog-card-two__content">
                                    <div className="blog-card__meta">
                                        <Link to="newsdetails"><i className="far fa-user-circle"></i> by Admin</Link>
                                        <Link to="newsdetails"><i className="far fa-comments"></i> 2 Comments</Link>
                                    </div>
                                    <h3 className="blog-card-two__title"><Link to="newsdetails">Better changing to
                                        grow business faster </Link></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="blog-card-two">
                                <div className="blog-card-two__image" style={{ backgroundImage: `url(${BGBL23})` }}>
                                </div>
                                <div className="blog-card-two__content">
                                    <div className="blog-card__meta">
                                        <Link to="newsdetails"><i className="far fa-user-circle"></i> by Admin</Link>
                                        <Link to="newsdetails"><i className="far fa-comments"></i> 2 Comments</Link>
                                    </div>
                                    <h3 className="blog-card-two__title"><Link to="newsdetails">Better changing to
                                        grow business faster </Link></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}



            {/* <section className="call-to-action-one call-to-action-one--has-bottom-margin">
                <div className="container">
                    <div className="call-to-action-one__inner wow fadeInUp" data-wow-duration="1500ms">
                        <div className="call-to-action-one__bg" style={{ backgroundImage: `url(${BGCta1})` }}></div>

                        <h3 className="call-to-action-one__title">
                            Are you ready to get credit
                            repair services?
                        </h3>
                        <div className="call-to-action-one__btns">
                            <Link to="/applynow" className="thm-btn">Schedule An Appointment</Link>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}


        </>
    )
}

export default Home3